<template>
    <vx-card :title="'Canvas Return Putaway'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="reqCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unlCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="territories" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="warehouse ? warehouse.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="sales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="loadingDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unloadingDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Putaway Item Lines</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="28%">SKU Code</th>
                            <th width="8%">Unload HU</th>
                            <th width="7%">Unload Qty</th>
                            <th width="12%">Status</th>
                            <th width="10%">HU</th>
                            <th width="7%">Qty</th>
                            <th width="15%">Batch</th>
                            <th width="15%">Warehouse Area</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(dataLine, index) in dataLines">
                            <!-- first row -->
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.add_line.length">
                                    <vs-input :value="dataLine.item_name" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.add_line.length">
                                    <vs-input :value="dataLine.ul_selected_hu" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.add_line.length">
                                    <vs-input :value="dataLine.ul_qty" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.option_status"
                                        v-model="dataLine.add_line[0].status"
                                        class="w-full"
                                        :clearable="false"
                                        @input="val => { onChangeStatus(val, index, 0); }"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.option_unit"
                                        v-model="dataLine.add_line[0].unit"
                                        class="w-full"
                                        :clearable="false"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input 
                                        type="number" 
                                        class="w-full" 
                                        :min="0"
                                        v-model="dataLine.add_line[0].qty">
                                    </vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.option_batch"
                                        v-model="dataLine.add_line[0].batch"
                                        class="w-full"
                                        :clearable="false"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.add_line[0].option_warehouse_area"
                                        v-model="dataLine.add_line[0].warehouse_area_code"
                                        class="w-full"
                                        :clearable="false"
                                    />
                                </td>
                                <td class="td vs-table--td align-top pt-2">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="dataLine.add_line.length > 1"
                                            @click.stop="removeRow('normal', index, 0)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row" />
                                        <vs-button @click.stop="addRow('normal', index)" size="small" color="success"
                                            v-if="dataLine.add_line.length == 1" icon-pack="feather" icon="icon-plus"
                                            title="Add Row" />
                                    </div>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(addLine, indexAddLine) in dataLines[index].add_line">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine" v-if="indexAddLine > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="dataLine.option_status"
                                            v-model="addLine.status"
                                            class="w-full"
                                            :clearable="false"
                                            @input="val => { onChangeStatus(val, index, indexAddLine) }"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="dataLine.option_unit"
                                            v-model="addLine.unit"
                                            class="w-full"
                                            :clearable="false"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input
                                            type="number"
                                            class="w-full"
                                            :min="0"
                                            v-model="addLine.qty">
                                        </vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="dataLine.option_batch"
                                            v-model="addLine.batch"
                                            class="w-full"
                                            :clearable="false"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="addLine.option_warehouse_area"
                                            v-model="addLine.warehouse_area_code"
                                            class="w-full"
                                            :clearable="false"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top pt-2">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="dataLine.add_line.length > 0"
                                                @click.stop="removeRow('normal', index, indexAddLine)" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row" />
                                            <vs-button @click.stop="addRow('normal', index)" size="small" color="success"
                                                v-if="dataLine.add_line.length == indexAddLine + 1"
                                                icon-pack="feather" icon="icon-plus" title="Add Row" />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- ... -->
        <br>
        <div class="vx-row mb-6" v-if="missingDataLines.length > 0">
            <div class="vx-col w-full">
                <h4>Missing Item Lines</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="28%">SKU Code</th>
                            <th width="7%">Missing Qty (UOM)</th>
                            <th width="8%">Status</th>
                            <th width="10%">HU</th>
                            <th width="7%">Qty</th>
                            <th width="15%">Batch</th>
                            <th width="20%">Warehouse Area</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(dataLine, index) in missingDataLines">
                            <!-- first row -->
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.add_line.length">
                                    <vs-input :value="dataLine.item_name" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.add_line.length">
                                    <vs-input :value="dataLine.ul_qty" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.add_line.length">
                                    <vs-input :value="dataLine.status" :readonly="true" class="w-full"></vs-input>
                                </td>

                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.option_unit"
                                        v-model="dataLine.add_line[0].unit"
                                        class="w-full"
                                        :clearable="false"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input 
                                        type="number" 
                                        class="w-full" 
                                        :min="0"
                                        v-model="dataLine.add_line[0].qty">
                                    </vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.option_batch"
                                        v-model="dataLine.add_line[0].batch"
                                        class="w-full"
                                        :clearable="false"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="dataLine.option_warehouse_area"
                                        v-model="dataLine.add_line[0].warehouse_area_code"
                                        class="w-full"
                                        :clearable="false"
                                    />
                                </td>
                                <td class="td vs-table--td align-top pt-2">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="dataLine.add_line.length > 1"
                                            @click.stop="removeRow('missing', index, 0)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row" />
                                        <vs-button @click.stop="addRow('missing', index)" size="small" color="success"
                                            v-if="dataLine.add_line.length == 1" icon-pack="feather" icon="icon-plus"
                                            title="Add Row" />
                                    </div>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(addLine, indexAddLine) in missingDataLines[index].add_line">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine" v-if="indexAddLine > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="dataLine.option_unit"
                                            v-model="addLine.unit"
                                            class="w-full"
                                            :clearable="false"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input
                                            type="number"
                                            class="w-full"
                                            :min="0"
                                            v-model="addLine.qty">
                                        </vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="dataLine.option_batch"
                                            v-model="addLine.batch"
                                            class="w-full"
                                            :clearable="false"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :options="dataLine.option_warehouse_area"
                                            v-model="addLine.warehouse_area_code"
                                            class="w-full"
                                            :clearable="false"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top pt-2">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="dataLine.add_line.length > 0"
                                                @click.stop="removeRow('missing', index, indexAddLine)" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row" />
                                            <vs-button @click.stop="addRow('missing', index)" size="small" color="success"
                                                v-if="dataLine.add_line.length == indexAddLine + 1"
                                                icon-pack="feather" icon="icon-plus" title="Add Row" />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Save</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            reqCode: null,
            unlCode: null,
            territories: null,
            warehouse: null,
            sales: null,
            loadingDate: null,
            unloadingDate: null,
            dataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    item_code: null,
                    ul_selected_hu: null,
                    ul_qty: 0,
                    option_batch: [],
                    option_status: [],
                    option_unit: [],
                    add_line: [{
                        putaway_id: 0,
                        status: null,
                        unit: null,
                        qty: 0,
                        batch: null,
                        option_warehouse_area: [],
                        warehouse_area_code: null,
                    }]
                }
            ],
            missingDataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    item_code: null,
                    ul_qty: 0,
                    status: null,
                    option_batch: [],
                    option_unit: [],
                    option_warehouse_area: [],
                    add_line: [{
                        putaway_id: 0,
                        unit: null,
                        qty: 0,
                        batch: null,
                        warehouse_area_code: null,
                    }]
                }
            ]
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/canvas/unloading/check-data-form/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.reqCode = resp.data.request_doc
                    this.unlCode = resp.data.unloading_doc
                    this.territories = resp.data.territories.label
                    this.warehouse = resp.data.warehouse
                    this.sales = resp.data.sales.label
                    this.loadingDate = resp.data.loading_date
                    this.unloadingDate = resp.data.unloading_date
                    this.dataLines = resp.data.data_lines
                    this.missingDataLines = resp.data.missing_data_lines
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.unloading",
                params: {status: 1}
            });
        },
        handleSubmit() {
            let message = ""
            let skus = []
            let missingSkus = []
            this.dataLines.forEach(val => {
                let addLines = []

                val.add_line.forEach((idx) => {
                    if (!idx.status) {
                        message = "please select item status for item "+val.item_name
                        return
                    }

                    if (!idx.unit) {
                        message = "please select HU for item "+val.item_name
                        return
                    }

                    if (idx.qty < 1) {
                        message = "quantity for item "+val.item_name+" must be greater than zero"
                        return
                    }

                    if (!idx.batch) {
                        message = "please select batch for item "+val.item_name
                        return
                    }

                    if (!idx.warehouse_area_code) {
                        message = "please select warehouse area for item "+val.item_name
                        return
                    }

                    addLines.push({
                        putaway_id: idx.putaway_id ? idx.putaway_id : 0,
                        status: idx.status,
                        unit: idx.unit,
                        qty: Number(idx.qty),
                        batch: idx.batch,
                        warehouse_area_code: idx.warehouse_area_code,
                    })
                })

                skus.push({
                    unloading_id: val.unloading_id ? val.unloading_id : 0,
                    sku_code: val.item_code,
                    add_lines: addLines,
                })
            })

            if (this.missingDataLines.length > 0) {
                this.missingDataLines.forEach(val => {
                    let addLines = []

                    val.add_line.forEach(idx => {
                        if (!idx.unit) {
                            message = "please select HU for missing item "+val.item_name
                            return
                        }

                        if (idx.qty < 1) {
                            message = "qty for missing item "+val.item_name+" must be greater than zero"
                            return
                        }

                        if (!idx.batch) {
                            message = "please select batch for missing item "+val.item_name
                            return
                        }

                        if (!idx.warehouse_area_code) {
                            message = "please select warehouse area for missing item "+val.item_name
                        }

                        addLines.push({
                            putaway_id: idx.putaway_id ? idx.putaway_id : 0,
                            unit: idx.unit,
                            qty: Number(idx.qty),
                            batch: idx.batch,
                            warehouse_area_code: idx.warehouse_area_code,
                        })
                    })

                    missingSkus.push({
                        unloading_id: val.unloading_id ? val.unloading_id : 0,
                        sku_code: val.item_code,
                        add_lines: addLines,
                    })
                })
            }

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let param = {
                skus: skus,
                missing_skus: missingSkus,
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to save this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.put("/api/wms/v1/canvas/unloading/save-putaway-data/" + this.id, param)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                            this.handleBack()
                            this.draw++;
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: `Error`,
                                text: resp.message,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.dialog({
                            color: "danger",
                            title: `Error`,
                            text: error,
                        });
                    });
                }
            });
        },
        onChangeStatus(val, index, indexAddLine) {
            this.dataLines[index].add_line[indexAddLine].warehouse_area_code = null

            let param = {
                warehouse_id: this.warehouse ? this.warehouse.id : 0,
                item_status: val,
            }

            this.$http.post("/api/wms/v1/canvas/unloading/get-storage-option", param)
            .then(resp => {
                if (resp.code == 200) {
                    if (resp.data != []) {
                        this.dataLines[index].add_line[indexAddLine].option_warehouse_area = resp.data
                    } else {
                        this.dataLines[index].add_line[indexAddLine].option_warehouse_area = []
                    }
                } else {
                    this.dataLines[index].add_line[indexAddLine].option_warehouse_area = []
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        removeRow(lineType, index, indexAddLine) {
            if (lineType == "normal") {
                this.dataLines[index].add_line.splice(indexAddLine, 1);
            } else {
                this.missingDataLines[index].add_line.splice(indexAddLine, 1)
            }
        },
        addRow(lineType, index) {
            if (lineType == "normal") {
                this.dataLines[index].add_line.push({
                    putaway_id: 0,
                    status: null,
                    unit: null,
                    qty: 0,
                    batch: null,
                    option_warehouse_area: [],
                    warehouse_area_code: null,
                });
            } else {
                this.missingDataLines[index].add_line.push({
                    putaway_id: 0,
                    unit: null,
                    qty: 0,
                    batch: null,
                    warehouse_area_code: null,
                })
            }
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>